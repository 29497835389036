<template>
  <div class="filter">
        <div class="filter__wrapper">
            <ul class="filter__list">
                <li class="filter__item" v-for="(filter, filterindex) in filters" :key="filterindex">
                    <input type="radio" name="portfolioSearch" v-model="search" :id="'portfolioSearch'+filterindex" :value="filter.value">
                    <label :for="'portfolioSearch'+filterindex" class="filter__label"><span v-html="filter.label"></span></label>
                </li>
            </ul>
        </div>
    </div>
</template>

<script>
export default {
    data(){
        return {
            filters: [
				{label: 'alles', value: ''},
				{label: 'affiches', value: 'affiche'},
				{label: 'websites', value: 'webdesign'},
				{label: 'logo&lsquo;s', value: 'logo'}
            ],
            search: ''
        }
    }
}
</script>

<style>

</style>