<template>
	<div class="portfolio-gallery" :series="series" :tags="tags">

		<transition name="slide-up" mode="out-in">
			<div class="portfolio-gallery__slideshow slide-up-element" key="overview" v-if="currentDetail == null">
				<div class="filter">
					<div class="filter__wrapper">
						<ul class="filter__list">
							<li class="filter__item" v-for="(filter, filterindex) in filters" :key="filterindex">
								<input type="radio" name="portfolioSearch" v-model="search" :id="'portfolioSearch'+filterindex" :value="filter.value">
								<label :for="'portfolioSearch'+filterindex" class="filter__label"><span v-html="filter.label"></span></label>
							</li>
						</ul>
					</div>
				</div>

				<transition-group name="fade-scale" tag="ul">
					<li class="portfolio-gallery__slide fade-scale-element" 
						@click="showDetail(slide)"
						v-for="slide in selectedWork" 
						:key="slide.title">
						<div class="portfolio-gallery__image">
							<img :src="slide.detailimage"  :alt="slide.title" v-if="slide.detailimage !== null">
						</div>
						<div class="portfolio-gallery__title">
							<h3>{{slide.title}}</h3>
						</div>
					</li>
				</transition-group>
			</div>		
				
			<div class="portfolio-gallery__detail slide-up-element" key="detail" :class="{'portfolio-gallery__otherdetail': detailSwitch}" v-else>
				<div class="portfolio-gallery__header">
					<h1>{{currentDetail.title}}</h1>
					<button class="portfolio-gallery__arrow portfolio-gallery__arrow--back" @click="hideDetail" title="Terug naar portfolio"></button>
					<h2>{{currentDetail.madeFor}}</h2>
				</div>
				<div class="portfolio-gallery__content">
					<div class="portfolio__description">
						<p v-html="currentDetail.description"></p>
					</div>
					<p v-if="currentDetail.url !== null"><a :href="currentDetail.url" target="_blank">{{urlLabel(currentDetail.url)}}</a></p>
					<ul v-if="currentDetail.tagID !== null" class="tags">
						<li class="tag" v-for="tag in currentDetail.tagID" :key="tag">{{tag}}</li>
					</ul>

					<div v-if="currentDetail.relatedTo !== null">
						<h3>Zie ook: </h3>
						<ul class="related-links">
							<li class="related-links__item" v-for="(work, id) in relatedWork(currentDetail.relatedTo)"  :key="id">
								<button class="btn btn--text-link" @click="showOtherDetail(work)">{{work.title}}</button>
							</li>
						</ul>
					</div>
					
				</div>

				<div class="portfolio__media">
					<div v-if="currentDetail.media.length > 0">
						<div class="portfolio__media-slider">
							<VueSlickCarousel v-bind="settingsDetailMain" ref="detailMain">
								<div class="portfolio__image" v-for="(image, index) in currentDetail.media" :key="index">
									<img :src="image" :alt="currentDetail.title">
								</div>
							</VueSlickCarousel>
						</div>
					</div>
				</div>
			</div>
		</transition>
		
	</div>
</template>

<script>
import VueSlickCarousel from 'vue-slick-carousel'
import router from '../../router'
import VueGtag from "vue-gtag";

Vue.use(VueGtag, {
  config: { id: "UA-2564562-10" },
  appName: 'Portfolio',
  pageTrackerScreenviewEnabled: true
})

export default {
	components: { VueSlickCarousel },
    props: {
		series: Array,
		tags: Array
    },
    data() {
        return {
			currentDetail: null,
			detailSwitch: false,
			search: '',
			filters: [
				{label: 'alles', value: ''},
				{label: 'affiches', value: 'affiche'},
				{label: 'websites', value: 'webdesign'},
				//{label: 'Haarlems Klein Koor', value: 'Haarlems Klein Koor'},
				{label: 'logo&lsquo;s', value: 'logo'}
			],
			settings: {
				infinite: false,
				initialSlide: 0,
				slidesToShow: 5,
				slidesToScroll: 5,
				touchThreshold: 5,
				lazyLoad: 'ondemand',
				variableWidth: true,
				responsive: [
					{
						breakpoint: 1200,
						settings: {
							slidesToShow: 3,
							slidesToScroll: 3
						}
					},
					{
						breakpoint: 768,
						settings: {
							slidesToShow: 2,
							slidesToScroll: 2
						}
					},
					{
						breakpoint: 480,
						settings: {
							slidesToShow: 1,
							slidesToScroll: 1
						}
					}
				]
			},
			settingsDetailMain: {
				arrows: false,
				dots: true,
				slidesToShow: 1,
				slidesToScroll: 1,
				fade: true,
				lazyLoad: 'ondemand'
			}
        }
	},
	computed: {
		overviewSeries(){
			var output = [];
			for(let i = 0; i < this.series.length; i++){			
				if(this.series[i].inOverview === 'true') {
					output.push(this.series[i]);
				}
			}
			return output;
		},
		selectedWork: function(){
            return this.overviewSeries.filter((item) => {
                var si = new RegExp(this.search, 'i');
                return item.madeFor.match(si) || item.title.match(si) || item.category.match(si);
            });
		}
	},
    methods: {
		isDetail(index) {
			return this.currentDetail == index;
		},
		showDetail(slide) {
			slide.tagID = this.convertToArray(slide.tagID);
			slide.media = this.convertToArray(slide.media);
			let seourl = slide.seourl;
			router.push({name: 'Portfolio', params: {seourl}});
			this.scrollUp();
			this.currentDetail = slide;			
		},
		hideDetail(){
			this.currentDetail = null;
			router.push({name: 'Home'});
		},
		showOtherDetail(slide) {
			this.detailSwitch = true;
			slide.tagID = this.convertToArray(slide.tagID);
			slide.media = this.convertToArray(slide.media);
			this.currentDetail = '';
			setTimeout(()=>{
				this.currentDetail = slide;
				let seourl = slide.seourl;
				router.push({name: 'Portfolio', params: {seourl}});
				this.detailSwitch = false;
			}, 500);
		},
		urlLabel(url) {
			return url.split('//')[1];
		},
		convertToArray(str) {
			if(Array.isArray(str)) {
				return str;
			} else {
				if(str !== null) {
					if(str.match(',')) {
						return str.split(',');
					}
					return [str];
				}
				return [];
			}
		},
		relatedWork(ids){
			var works = ids.split(',');
			for(let i = 0; i < works.length; i++){
				for(let j = 0; j < this.series.length; j++){
					if(this.series[j].ID == works[i]) {
						works[i] = this.series[j];
					}
				}
			}
			return works;
		},
		deeplink(){
			let urlArray = String(window.location).split('portfolio/');
			if(urlArray.length > 1) {
				let portfolioID = urlArray[1];
				for(let i = 0; i < this.series.length; i++){
					if(this.series[i].seourl == portfolioID) {
						this.showDetail(this.series[i]);
					}
				}
			}
		},
		scrollUp(){
			console.log('scroll up');
			window.scrollTo(0,0);
		}
	},
	mounted() {
		this.deeplink();
	}
}
</script>
