import Vue from 'vue'
import VueRouter from 'vue-router'
import PortfolioOverview from '@/components/PortfolioOverview';
import PortfolioDetail from '@/components/PortfolioDetail';

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: 'Home',
    component: PortfolioOverview,
    props: true
  },
  
  {
    path: '/portfolio/:seourl',
    name: 'Portfolio',
    component: PortfolioDetail,
    props: true
  }
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})

export default router
