<template>
    <div class="portfolio-gallery__detail" :class="{'portfolio-gallery__otherdetail': detailSwitch}" v-if="currentDetail !== null && currentDetail !== ''">
        <router-link to="{name:'Home'}">Home</router-link>
        <div class="portfolio-gallery__header">
            <h1>{{currentDetail.title}}</h1>
            <button class="portfolio-gallery__arrow portfolio-gallery__arrow--back" @click="hideDetail" title="Terug naar portfolio"></button>
            <h2>{{currentDetail.madeFor}}</h2>
        </div>
        <div class="portfolio-gallery__content">
            <div class="portfolio__description">
                <p v-html="currentDetail.description"></p>
            </div>
            <p v-if="currentDetail.url !== null"><a :href="currentDetail.url" target="_blank">{{urlLabel(currentDetail.url)}}</a></p>
            <ul v-if="currentDetail.tagID !== null" class="tags">
                <li class="tag" v-for="tag in currentDetail.tagID" :key="tag">{{tag}}</li>
            </ul>

            <div v-if="currentDetail.relatedTo !== null">
                <h3>Zie ook: </h3>
                <ul class="related-links">
                    <li class="related-links__item" v-for="(work, id) in relatedWork(currentDetail.relatedTo)"  :key="id">
                        <button class="btn btn--text-link" @click="showOtherDetail(work)">{{work.title}}</button>
                    </li>
                </ul>
            </div>
            
        </div>

        <div class="portfolio__media">
            <div v-if="currentDetail.media.length > 0">
                <div class="portfolio__media-slider">
                    <VueSlickCarousel v-bind="settingsDetailMain" ref="detailMain">
                        <div class="portfolio__image" v-for="(image, index) in currentDetail.media" :key="index">
                            <img :src="image" :alt="currentDetail.title">
                        </div>
                    </VueSlickCarousel>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import VueSlickCarousel from 'vue-slick-carousel'

export default {
    data(){
        return {
            settings: {
				infinite: false,
				initialSlide: 0,
				slidesToShow: 5,
				slidesToScroll: 5,
				touchThreshold: 5,
				lazyLoad: 'ondemand',
				variableWidth: true,
				responsive: [
					{
						breakpoint: 1200,
						settings: {
							slidesToShow: 3,
							slidesToScroll: 3
						}
					},
					{
						breakpoint: 768,
						settings: {
							slidesToShow: 2,
							slidesToScroll: 2
						}
					},
					{
						breakpoint: 480,
						settings: {
							slidesToShow: 1,
							slidesToScroll: 1
						}
					}
				]
			},
        }
    },
    computed: {},
    methods: {}
}
</script>

<style>

</style>