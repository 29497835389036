<template>
    <div class="portfolio" :series="series" :tags="tags">
        <div class="filter">
            <div class="filter__wrapper">
                <ul class="filter__list">
                    <li class="filter__item" v-for="(filter, filterindex) in filters" :key="filterindex">
                        <input type="radio" name="portfolioSearch" v-model="search" :id="'portfolioSearch'+filterindex" :value="filter.value">
                        <label :for="'portfolioSearch'+filterindex" class="filter__label"><span v-html="filter.label"></span></label>
                    </li>
                </ul>
            </div>
        </div>
        
        <transition-group name="fade-out-in" tag="ul" class="portfolio-gallery__slides">
            <li class="portfolio-gallery__slide" 
                @click="showDetail(slide)"
                v-for="slide in selectedWork" 
                :key="slide.ID">
                <div class="portfolio-gallery__image">
                    <img :src="slide.detailimage"  :alt="slide.title" v-if="slide.detailimage !== null">
                </div>
                <div class="portfolio-gallery__title">
                    <h3>{{slide.title}}</h3>
                </div>
            </li>
        </transition-group>
    </div>
</template>

<script>
import PortfolioFilter from '@/components/PortfolioFilter';

export default {
    components: {
        PortfolioFilter
    },
    props: {
        series: Array,
        tags: Array
    },
    data(){
        return {
            filters: [
				{label: 'alles', value: ''},
				{label: 'affiches', value: 'affiche'},
				{label: 'websites', value: 'webdesign'},
				{label: 'logo&lsquo;s', value: 'logo'}
            ],
			currentDetail: null,
            detailSwitch: false,
            search: ''
        }
    },
    computed: {
        overviewSeries(){
			var output = [];
			for(let i = 0; i < this.series.length; i++){
				if(this.series[i].inOverview === 'true') {
					output.push(this.series[i]);
				}
			}
			return output;
		},
		selectedWork: function(){
            return this.overviewSeries.filter((item) => {
                var si = new RegExp(this.search, 'i');
                return item.madeFor.match(si) || item.title.match(si) || item.category.match(si);
            });
		}
    },
    methods: {
		isDetail(index) {
			return this.currentDetail == index;
		},
		showDetail(slide) {
			slide.tagID = this.convertToArray(slide.tagID);
			slide.media = this.convertToArray(slide.media);
            let seourl = slide.seourl;
            router.push({name: 'Portfolio', params: {seourl}});
			this.scrollUp();
			this.currentDetail = slide;			
		},
		hideDetail(){
			this.currentDetail = null;
			router.push({name: 'Home'});
		},
		showOtherDetail(slide) {
			this.detailSwitch = true;
			slide.tagID = this.convertToArray(slide.tagID);
			slide.media = this.convertToArray(slide.media);
			this.currentDetail = '';
			setTimeout(()=>{
				this.currentDetail = slide;
				let seourl = slide.seourl;
				router.push({name: 'Portfolio', params: {seourl}});
				this.detailSwitch = false;
			}, 500);
		},
		urlLabel(url) {
			return url.split('//')[1];
		},
		convertToArray(str) {
			if(Array.isArray(str)) {
				return str;
			} else {
				if(str !== null) {
					if(str.match(',')) {
						return str.split(',');
					}
					return [str];
    }
				return [];
			}
		},
		relatedWork(ids){
			var works = ids.split(',');
			for(let i = 0; i < works.length; i++){
				for(let j = 0; j < this.series.length; j++){
					if(this.series[j].ID == works[i]) {
						works[i] = this.series[j];
					}
				}
			}
			return works;
		},
		deeplink(){
			let urlArray = String(window.location).split('portfolio/');
			if(urlArray.length > 1) {
				let portfolioID = urlArray[1];
				for(let i = 0; i < this.series.length; i++){
					if(this.series[i].seourl == portfolioID) {
						this.showDetail(this.series[i]);
					}
				}
			}
		},
		scrollUp(){
			window.scrollTo(0,0);
		}
	},
	mounted() {
		this.deeplink();
	}
}
</script>

<style lang="scss">
.fade-out-in-move {
  transition: transform 0.5s ease-in-out;
  transition-delay: .2s;
}

.fade-out-in-enter-active,
.fade-out-in-leave-active {
  transition: opacity .5s;
}

.fade-out-in-enter-active {
  transition-delay: .5s;
}

.fade-out-in-enter,
.fade-out-in-leave-to {
  opacity: 0;
}
</style>